var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('div',{staticClass:"d-flex flex-row flex-wrap"},[_c('div',{staticClass:"col-12 px-0"},[_c('filter-master',{attrs:{"m-headquarter-id":_vm.editData && _vm.editData.mHeadquarterId
            ? _vm.editData.mHeadquarterId
            : !_vm.authUser.canAccessAllHeadQuarters
            ? _vm.authUser.mHeadquarterId
            : null,"disabled-input":_vm.disabledInput,"col-headquarter":"12","col-division":"12","default-label":"-","hidden-input":{
          division: true,
          station: true,
          school: true,
          schoolClass: true,
          room: true,
        },"invalid":_vm.invalid,"validation":_vm.validation},model:{value:(_vm.filterMaster),callback:function ($$v) {_vm.filterMaster=$$v},expression:"filterMaster"}})],1),_c('div',{staticClass:"col-12 px-2 mb-2"},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"stationName"}},[_vm._v("จังหวัด")]),_c('b-form-input',{attrs:{"id":"stationName","state":_vm.invalid ? _vm.validation.form.m_division_name.required : null,"type":"text","disabled":_vm.disabled},model:{value:(_vm.form.m_division_name),callback:function ($$v) {_vm.$set(_vm.form, "m_division_name", $$v)},expression:"form.m_division_name"}}),_c('b-form-invalid-feedback',{attrs:{"id":"stationName-feedback"}},[_vm._v(" กรุณากรอกจังหวัด ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }