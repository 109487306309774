<template>
  <b-modal
    id="create-or-update-division-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">เพิ่ม/แก้ไขข้อมูลจังหวัด</h3>
      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด: {{ editData.updatedAt }}</span
      >
    </template>

    <!-- <filter-master
        v-model="formfilter"
        col-headquarter="12"
        col-division="12"
        :hiddenInput="{ division: true, school: true, schoolClass: true }"
        :invalid="invalid"
        :validation="$v"
    >
    </filter-master> -->

    <division-form
      v-model="form"
      :filter-master.sync="filterMaster"
      :edit-data="editData"
      :disabled="isSubmiting"
      :invalid.sync="invalid"
      :validation="$v"
    ></division-form>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button size="sm" variant="primary" @click.prevent="save">
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import formatDateMixin from "../../mixins/formatDateMixin";
import validatorMixin from "../../mixins/validatorMixin";
import DivisionForm from "../form/Division";
import { Auth, MDivision } from "../../models";

export default {
  mixins: [formatDateMixin, validatorMixin, validationMixin],

  components: {
    DivisionForm,
  },

  props: {
    show: Boolean,
    editData: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      isSubmiting: false,
      invalid: false,
      form: {},
      filterMaster: {},
    };
  },

  validations: {
    form: {
      m_division_name: { required },
    },
    filterMaster: {
      mHeadquarterId: { required },
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

  methods: {
    async save() {
      this.$v.$touch();

      this.invalid = false;

      if (this.$v.$invalid) {
        this.invalid = true;

        return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      }

      const formData = {
        ...this.form,
        mHeadquarterId: this.filterMaster.mHeadquarterId,
      };

      let promise;

      this.isSubmiting = true;

      try {
        if (this.editData && this.editData.id) {
          promise = await MDivision.api().update(this.editData.id, formData);
        } else {
          promise = await MDivision.api().create(formData);
        }

        const { data } = promise.response;

        if (data && data.response_status == "SUCCESS") {
          this.$toast.success(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลจังหวัดเรียบร้อยแล้ว`
          );

          this.$bvModal.hide("create-or-update-division-modal");

          this.$emit("create:success");
        } else {
          this.$toast.error(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลจังหวัดไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          );
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.invalid = false;
      this.form = {};

      this.$emit("update:editData", {});
    },
  },
};
</script>
