<template>
  <page-content>
    <page-title heading="โครงสร้างจังหวัด">
      <template v-slot:right>
        <b-button variant="danger" @click="onCreate">เพิ่มจังหวัด</b-button>
      </template>
    </page-title>

    <b-row align-v="center" class="mt-4 mt-md-0">
      <b-col cols="12">
        <filter-master
          v-model="formfilter"
          col-headquarter="4"
          :hiddenInput="{
            division: true,
            station: true,
            school: true,
            schoolClass: true,
            room: true,
          }"
        >
          <b-col md="8" class="px-0">
            <b-row align-v="end" class="pt-4 pr-sm-2">
              <b-col sm="8" class="mt-2 pr-sm-0">
                <b-form-group class="px-2 mb-0">
                  <b-input-group class="input-group-seamless">
                    <b-form-input
                      placeholder="ค้นหารายชื่อจังหวัด"
                      v-model="keyword"
                      @keyup.enter="onKeywordSearched"
                    ></b-form-input>

                    <b-input-group-text slot="prepend">
                      <font-awesome-icon class="mx-auto" icon="search" />
                    </b-input-group-text>

                    <b-input-group-text v-show="keyword.length" slot="append">
                      <a href="javascript:void(0)" @click="clearKeyword">
                        <font-awesome-icon
                          class="mx-auto"
                          icon="window-close"
                        />
                      </a>
                    </b-input-group-text>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col sm="4" class="mt-4 mt-sm-0 px-sm-3 px-4">
                <b-button
                  variant="primary"
                  block
                  size="sm"
                  @click="onKeywordSearched"
                  >ค้นหา</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </filter-master>
      </b-col>
    </b-row>

    <division-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="divisionTableParams"
      @edit="onEdit"
      @delete="onDelete"
    />

    <create-or-update-division
      :edit-data.sync="editData"
      v-model="showModalDivision"
      @create:success="onCreateSuccess"
    ></create-or-update-division>

    <delete-division
      v-model="showModalDelete"
      @delete:success="onDeleteSuccess"
      :delete-data.sync="deleteData"
    ></delete-division>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateDivision from "../../components/modal/CreateOrUpdateDivision";
import DivisionTable from "../../components/table/Division";
import DeleteDivision from "../../components/modal/DeleteDivision";
import FilterMaster from "../../components/form/FilterMaster";
import { Auth } from "../../models";

export default {
  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateDivision,
    DivisionTable,
    DeleteDivision,
    FilterMaster,
  },

  data() {
    return {
      keyword: "",
      formfilter: {},
      editData: {},
      deleteData: {},
      showModalDivision: false,
      showModalDelete: false,
      fields: [
        { key: "m_headquarters_name", label: "ภาค", sortable: true },
        { key: "m_division_name", label: "จังหวัด", sortable: true },
        {
          key: "createdAt",
          label: "วันที่สร้าง",
          sortable: true,
        },
        {
          key: "updatedAt",
          label: "แก้ไขล่าสุด",
          sortable: true,
        },
      ],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    divisionTableParams() {
      const { formfilter } = this;

      return {
        ...formfilter,
      };
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    onCreate() {
      this.showModalDivision = true;
    },

    async onEdit(item) {
      let mHeadquarterId = null;

      if (item.m_headquarter) {
        mHeadquarterId = item.m_headquarter.id;
      }

      this.editData = { ...item, mHeadquarterId };

      this.showModalDivision = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };

      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
